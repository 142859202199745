import { FeedTypes } from "@/helpers/pageHelpers/Common/interface";

import { Schema } from "./Head.interface";

const buildDomain = process.env.NEXT_PUBLIC_DOMAIN;

export const getServerPathBySlug = (
  schema: Schema | FeedTypes,
  slug: string
): string => {
  switch (schema) {
    case Schema.Article:
    case FeedTypes.Article:
      return buildDomain + "/artykul/" + slug;
    case Schema.Author:
      return buildDomain + "/redaktor/" + slug;
    case Schema.Page:
      return null;
    case Schema.Podcast:
      return buildDomain + "/podcast/" + slug;
    case Schema.Episode:
    case FeedTypes.Episode:
      return buildDomain + "/epizod/" + slug;
    case Schema.Video:
    case FeedTypes.Video:
      return buildDomain + "/wideo/" + slug;
    default:
      return null;
  }
};
