import { MetaDataInterface } from "@/helpers/pageHelpers/Common/interface";

export const defaultOGImage =
  "https://cdn.newonce.me/uploads/og_facebook/portalconfiguration/net/og_image_newoncev3.jpg";

export const radioMetaData: MetaDataInterface = {
  metaTitle: "słuchaj live - newonce.radio",
  description:
    "Radio internetowe, które ucieka schematom. 24/7 muzyka, rozrywka, kultura, nauka i sport. Wojewódzki, Kędzierski, Szydłowska, Okuniewska, Bodnar, Rudzki i inni. Słuchaj bez ograniczeń.",
  ogFbTitle: "słuchaj live - newonce.radio",
  ogFbImage: defaultOGImage,
};

export const podcastMetaData: MetaDataInterface = {
  metaTitle: "Podcasty - newonce.radio",
  description:
    "Najlepsze polskie podcasty i ich nagradzani twórcy. Wojewódzki, Kędzierski, Okuniewska, Szydłowska, Masłowska, Dwie Dupy o Dupie, Futbol i Cała reszta, Jurasówka i dziesiątki innych. Raz posłuchasz, zostaniesz na długo. ",
  ogFbTitle: "Podcasty - newonce.radio",
};

export const articlesFeedMetaData: MetaDataInterface = {
  metaTitle: "Artykuły – newonce.net",
  description:
    "Opinie, recenzje i formy reportażowe pisane przez naszych dziennikarzy/-rki. Seriale, kino, technologia, społeczeństwo, muzyka i gaming.",
  ogFbTitle: "Artykuły – newonce.net",
};

export const videosFeedMetaData: MetaDataInterface = {
  metaTitle: "Wideo - newonce.net",
  description:
    "Wszystkie formaty wideo w jednym miejscu. Ucho, Pa Na To, net.talks oraz wydania specjalne.",
  ogFbTitle: "Wideo - newonce.net",
};

export const authorsdMetaData: MetaDataInterface = {
  metaTitle: "Wszyscy ludzie newonce.net",
  description:
    "Szukasz treści danego autora/-ki? Wszystkie artykuły, audycje i podcasty przypisane do konkretnych twórców/-czyń. Fall, Podhalicz, Lachmirowicz, Sobczyński, Podgórska, Kalinowski, Klimczak – obserwuj i bądź na bieżąco.",
  ogFbTitle: "Wszyscy ludzie newonce.net",
};

export const tagsMetaData = (tagName: string): MetaDataInterface => ({
  metaTitle: `${tagName} - newonce.net`,
  description: `Szukasz treści na konretny temat? Wszystkie artykuły i podcasty z tagiem "${tagName}" w jednym miejscu – obserwuj i bądź na bieżąco.`,
  ogFbTitle: `${tagName} - newonce.net`,
});

export const searchMetaData: MetaDataInterface = {
  title: "Szukajka",
  description:
    "Wczytuj się w artykuły, dzięki którym lepiej zrozumiesz, wokół czego kręci się świat kultury popularnej, wsłuchuj się w audycje radiowe na żywo w opcji 24/7 i ekscytuj się tysiącami godzin podcastów - to jakieś pół roku słuchania bez przerwy! Dodatkowo, dowiaduj się więcej o artystach, korzystając z naszej bazy muzycznej.",
  ogFbTitle: "newonce - szukajka",
  ogFbImage: defaultOGImage,
};
