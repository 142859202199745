import { Schema, SchemaData } from "./Head.interface";

// @todo: Add rich schemaData resolver for Author and video
export const resolveSchema = (schemaData: SchemaData): string => {
  switch (schemaData.type) {
    case Schema.Article:
      const articleData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: schemaData.title,
        url: process.env.NEXT_PUBLIC_DOMAIN + `/artykul/${schemaData.slug}`,
        thumbnailUrl: schemaData.image,
        datePublished: schemaData.datePublished,
        articleSection: !!schemaData.tags?.length
          ? schemaData.tags[0]?.title
          : "",
        creator: schemaData.author,
        keywords: !!schemaData.subtags?.length
          ? schemaData.subtags.map(({ title }) => title)
          : [],
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": "https://google.com/article",
        },
        image: [schemaData.image],
        dateModified: schemaData.dateModified,
        author: {
          "@type": "Person",
          name: schemaData.author,
        },
        publisher: {
          "@type": "Organization",
          name: "newonce",
          logo: {
            "@type": "ImageObject",
            url:
              process.env.NEXT_PUBLIC_DOMAIN +
              "/logo-" +
              process.env.NEXT_PUBLIC_PORTAL +
              ".png",
          },
        },
        articleType: schemaData?.articleType,
      };
      return JSON.stringify(articleData);
    case Schema.Page:
      const pageData = {
        "@context": "http://schema.org",
        "@type": "WebPage",
        headline: schemaData.title,
        url: process.env.NEXT_PUBLIC_DOMAIN + `/${schemaData.slug}`,
        ...schemaData,
      };
      return JSON.stringify(pageData);
    case Schema.Artist:
    case Schema.Release:
      const musicDBData = {
        "@context": "http://schema.org",
        "@type": "CreativeWork",
        headline: schemaData.title,
        url: process.env.NEXT_PUBLIC_DOMAIN + `/${schemaData.slug}`,
        ...schemaData,
      };
      return JSON.stringify(musicDBData);
    case Schema.Radio:
      const radioData = {
        "@context": "http://schema.org",
        "@type": "BroadcastService",
        name: schemaData.title,
        broadcastDisplayName: "newonce.radio",
        broadcaster: "newonce.radio",
        url: process.env.NEXT_PUBLIC_DOMAIN + `/radio`,
      };
      return JSON.stringify(radioData);
    case Schema.Episode:
      const episodeData = {
        "@context": "https://schema.org",
        "@type": "Episode",
        headline: schemaData.title,
        url: process.env.NEXT_PUBLIC_DOMAIN + `/epizod/${schemaData.slug}`,
        keywords: !!schemaData.tags?.length
          ? schemaData.tags.map(({ title }) => title)
          : [],
      };

      return JSON.stringify(episodeData);
    case Schema.Video:
      const videoData = {
        "@context": "https://schema.org",
        "@type": "video",
        headline: schemaData.title,
        url: process.env.NEXT_PUBLIC_DOMAIN + `/wideo/${schemaData.slug}`,
        keywords: !!schemaData.tags?.length
          ? schemaData.tags.map(({ title }) => title)
          : [],
      };

      return JSON.stringify(videoData);
    case Schema.Podcast:
      return null; // Do not implement rich schemaData on podcasts now since requires additional work with RSS feed
    default:
      return null;
  }
};
