import React, { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import { defaultOGImage } from "@/helpers/pageMetaData";
import { useDataLayer } from "@/helpers/useDataLayer";

import { MetaHeadProps, Schema } from "./Head.interface";
import { resolveSchema } from "./resolveRichSchema";
import { getServerPathBySlug } from "./getServerPathBySlug";

const defaultTitleTemplate = " | newonce";
const portal = process.env.NEXT_PUBLIC_PORTAL;

const MetaHead: React.FC<MetaHeadProps> = ({ metaData, schemaData }) => {
  const { pushToDataLayer } = useDataLayer();
  const {
    description,
    ogFbTitle,
    ogFbDescription,
    ogFbImage,
    ogTwTitle,
    ogTwDescription,
    ogTwImage,
  } = metaData;
  const metaTitle = metaData.title
    ? metaData.title + defaultTitleTemplate
    : metaData?.metaTitle;
  const url =
    metaData.slug &&
    schemaData?.type &&
    getServerPathBySlug(schemaData.type, metaData.slug);
  const isArticle = schemaData?.type === Schema.Article;
  const router = useRouter();
  const appName = portal === "net" ? "newonce" : "newoncesport";
  const appLink = `${appName}:/${router.asPath}`;

  useEffect(() => {
    pushToDataLayer("", { title: metaTitle });
  }, [metaData]);

  const mainSchemaData = {
    name: "newonce",
    alternateName: "newonce.net",
    url: process.env.NEXT_PUBLIC_DOMAIN,
    ...schemaData,
  };
  const isMainPage = router.asPath === "/";

  return (
    <Head>
      <meta property="al:ios:url" content={appLink} />
      <meta property="al:android:url" content={appLink} />

      <meta
        key="description"
        name="description"
        content={description || metaData.metaDescription}
      />
      <meta
        property="fb:app_id"
        content={process.env.NEXT_PUBLIC_FACEBOOK_API_KEY}
      />
      <title key="title">{metaTitle}</title>
      {metaData.canonical && (
        <link key="canonical" rel="canonical" href={metaData.canonical} />
      )}
      {url && schemaData?.type && (
        <meta key="og-fb-url" property="og:url" content={url} />
      )}
      <meta key="og-fb-title" property="og:title" content={ogFbTitle} />
      <meta
        key="og-fb-description"
        property="og:description"
        content={ogFbDescription}
      />
      <meta
        key="og-fb-image"
        property="og:image"
        content={ogFbImage ? ogFbImage : defaultOGImage}
      />
      <meta
        key="og-fb-image-secure"
        property="og:image:secure_url"
        content={ogFbImage}
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta key="og-tw-url" property="twitter:url" content={url} />
      <meta key="og-tw-title" property="twitter:title" content={ogTwTitle} />
      <meta
        key="og-tw-description"
        property="twitter:description"
        content={ogTwDescription}
      />
      <meta key="og-tw-image" property="twitter:image" content={ogTwImage} />

      {isArticle ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}

      {schemaData?.author && <meta name="author" content={schemaData.author} />}

      {schemaData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: resolveSchema(isMainPage ? mainSchemaData : schemaData),
          }}
        />
      )}
    </Head>
  );
};

export default MetaHead;
