import {
  MetaDataInterface,
  SlugType,
  TagInterface,
} from "@/helpers/pageHelpers/Common/interface";
import { ArticleType } from "@/helpers/pageHelpers/Article/interface";

export enum Schema {
  Article = "Article",
  Podcast = "Podcast",
  Episode = "Episode",
  Page = "Page",
  Author = "Author",
  Video = "Video",
  Radio = "Radio",
  Artist = "Artist",
  Release = "Release",
}

export interface SchemaData {
  type: Schema;
  title: string;
  image?: string;
  datePublished?: string;
  dateModified?: string;
  author?: string;
  slug?: SlugType;
  tags?: TagInterface[];
  subtags?: TagInterface[];
  articleType?: ArticleType;
}

export interface MetaHeadProps {
  schemaData?: SchemaData;
  metaData: MetaDataInterface;
}
